.popupGrid{
    display: grid;
    justify-items: center;
    gap: 2em;
}

.popupGrid_row{
    display: grid;
    justify-items: center;
    gap: 2em;
    grid-auto-flow: column;
}

.datesInput{
    flex: 1;
}