@import "fonts.scss";

:root {
    box-sizing: border-box;

    //colours
    --tomatoRed: #E71E2B;
    --tomatoRed-rgb: 231, 30, 43;
    --bluebarry: #3b2942;
    --bluebarry-rgb: 59, 41, 66;
    --beauBlue: #CBD3E3;
    --beauBlue-rgb: 203, 211, 227;
    --mainBGColor: white;
    --borderColor: #E5E5E5;

    --hoverColor: #F5F6F9;

    --header-height: 56px;
    @media (min-width: 600px){
        --header-height: 64px;
    }
}

div.notion-app{
    min-height: auto;
    .notion-header{
        top: var(--header-height);
    }
    .notion-page-scroller{
        min-height: auto;
    }

    // details{
    //     summary{
    //         &::marker{
    //             content: '+';
    //             font-size: 1.8em;
    //         }
    //     }
    //     &[open]{
    //         summary{
    //             &::marker{
    //                 content: '-';
    //                 font-size: 2.5em;
    //             }
    //         }
    //     }
    // }
}

