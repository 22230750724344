.promocodesList{
    border: 1px solid grey;
    border-radius: 4px;

    max-height: 400px;
    overflow-y: scroll;
}
.loader{
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
    width: '100%'
}

.promocodesListitem{
    &.promocodesListitem_inactive{
        opacity: 0.5;    
    }
}
