@font-face {
    font-family: 'GT-Pressura-LC';
    src: local('GT-Pressura-LC Light Italic'), local('GT-Pressura-LC-Light-Italic'),
            url('assets/fonts/GT-Pressura-LC-Light-Italic.woff2') format('woff2'),
            url('assets/fonts/GT-Pressura-LC-Light-Italic.woff') format('woff'),
            url('assets/fonts/GT-Pressura-LC-Light-Italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'GT-Pressura-LC';
    src: local('GT-Pressura-LC Light'), local('GT-Pressura-LC-Light'),
            url('assets/fonts/GT-Pressura-LC-Light.woff2') format('woff2'),
            url('assets/fonts/GT-Pressura-LC-Light.woff') format('woff'),
            url('assets/fonts/GT-Pressura-LC-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'GT-Pressura-LC';
    src: local('GT-Pressura-LC Regular Italic'), local('GT-Pressura-LC-Regular-Italic'),
            url('assets/fonts/GT-Pressura-LC-Regular-Italic.woff2') format('woff2'),
            url('assets/fonts/GT-Pressura-LC-Regular-Italic.woff') format('woff'),
            url('assets/fonts/GT-Pressura-LC-Regular-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'GT-Pressura-LC';
    src: local('GT-Pressura-LC Regular'), local('GT-Pressura-LC-Regular'),
            url('assets/fonts/GT-Pressura-LC-Regular.woff2') format('woff2'),
            url('assets/fonts/GT-Pressura-LC-Regular.woff') format('woff'),
            url('assets/fonts/GT-Pressura-LC-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
